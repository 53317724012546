import { Link } from '@/navigation';
import Image from "next/image";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { RatingStar } from "@/components";
import { Sake } from "@/types";
import AddToCartButtonSection from "./AddToCartButtonSection";

export default function Body({
  sake,
}: {
  sake: Sake;
}) {
  const brewery = sake?.brewery;

  return (
    <div className="py-16 sp:py-10">
      <div className="flex items-center">
        <div className="basis-2/6 flex justify-center items-center sp:hidden">
          <Image
            src={sake?.labelImageUrl ? `${process.env.NEXT_PUBLIC_SAKE_LABEL_IMAGE_URL_BASE}${sake.labelImageUrl}` : "/images/no-image-sake.png"}
            width={120}
            height={120}
            alt={sake?.name || "-"}
            title={sake?.name || "-"}
          />
        </div>
        <div className="basis-4/6 sp:basis-auto">
          <div className="sp:flex sp:items-center sp:mb-6">
            <div className="hidden sp:block sp:px-4 tb:px-8">
              <Image
                src={sake.labelImageUrl ? `${process.env.NEXT_PUBLIC_SAKE_LABEL_IMAGE_URL_BASE}${sake.labelImageUrl}` : "/images/no-image-sake.png"}
                width={110}
                height={110}
                alt={sake.name}
                title={sake.name}
                className="sp:w-16 sp:min-w-16"
              />
            </div>
            <div className="sp:flex-grow">
              <h3 className="font-semibold text-3xl sp:text-lg sp:text-center">{sake.name || "-"}</h3>
              {brewery && brewery.name && (
                <div className="py-2 sp:py-1 sp:flex sp:flex-col sp:items-center">
                  <Link
                    href={`/breweries/${brewery.id}`}
                    title={brewery.name}
                    className="text-secondary font-bold sp:text-sm"
                  >
                    {brewery.name}
                  </Link>
                  <div className="flex items-center mt-1 sp:mt-0">
                    <MapPinIcon className="h-5 w-5 sp:h-4 sp:w-4" />
                    <p className="text-xs">{brewery.prefecture}</p>
                  </div>
                </div>
              )}
              <div className="flex mt-4 sp:mt-2 sp:w-full">
                <div className="flex items-center sp:flex-col sp:w-full">
                  {sake.averageScore ? (
                    <div className="border-r pr-8 mr-8 sp:border-none sp:pr-0 sp:mr-0 sp:mb-6">
                      <p className="text-sm text-neutral mb-1 sp:text-xs">{`Score from Experts`}</p>
                      <div className="flex items-center sp:justify-center" >
                        <Image
                          src={"/images/verified.svg"}
                          width={40}
                          height={40}
                          alt="verified"
                          title="verified"
                          unoptimized
                          className="sp:w-[32px]"
                        />
                        <p className="text-4xl ml-1 sp:text-2xl">{sake.averageScore}</p>
                      </div>
                    </div>
                  ) : null}
                  <div className="flex items-center sp:flex-col">
                    <div className="min-w-[160px] mr-2 sp:mr-0 sp:mb-6 sp:min-w-0">
                      <p className="text-sm text-neutral mb-1 sp:text-xs">{`Rating from Customers`}</p>
                      <div className="flex items-center mr-8 sp:justify-center sp:mr-0">
                        <p className="text-4xl sp:text-2xl">{sake.averageRating || "-"}</p>
                        <div className="ml-3 sp:ml-2 sp:flex sp:flex-col">
                          <RatingStar rating={sake.averageRating} />
                          <p className="text-sm sp:text-xs sp:leading-none sp:mt-1">{`${sake.reviewCount || "no"} reviews`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="whitespace-pre-line bg-custom-light-gray font-light p-6 mt-4 sp:p-4 sp:text-sm">{sake.description || "no description"}</div>
          <div className="flex justify-center mt-6 sp:mt-4">
            <AddToCartButtonSection sake={sake} />
            <Link
              href={`/products/${sake.id}`}
              title={sake.name}
              className="text-secondary font-bold"
            >
              <button className="btn btn-primary btn-wide text-base font-bold px-8 text-white rounded-full">
                View more
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
