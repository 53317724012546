'use client'

export default function HeadingContent({
  value,
  id,
}: {
  value: string;
  id: string,
}) {
  return (
    <div id={id}>
      <h2 className="font-semibold text-3xl leading-relaxed mt-12 mb-2 sp:font-medium sp:text-xl sp:mt-10">
        {value}
      </h2>
    </div>
  );
}
