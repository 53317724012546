'use client';

import React, { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "@/components";
import { Link } from "@/navigation";
import { useCarts } from "@/hooks/shopify/useCarts";
import { useSession } from "next-auth/react";
import {
  ShoppingCartIcon,
  BuildingStorefrontIcon,
} from "@heroicons/react/24/outline";

export default function CartButton() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { data: carts, isLoading, mutate } = useCarts();
  const { data: session } = useSession();

  useEffect(() => {
    mutate();
  }, [session]);

  if (isLoading || !carts) return null;

  const totalQuantity = carts.reduce((total, cart) => {
    return total + cart.totalQuantity;
  }, 0);

  return (
    <div className="dropdown dropdown-end">
      <div
        tabIndex={0}
        role="button"
        className="relative flex items-center gap-x-1 cursor-pointer"
        onClick={() => setIsDropdownOpen(prev => !prev)}
      >
        <ShoppingCartIcon
          width="34"
          height="34"
          className="sp:w-[30px] sp:h-[30px]"
        />
        {totalQuantity > 0 && (
          <div
            className="absolute -top-2.5 -right-2.5 h-6 w-6 rounded-full bg-custom-red text-white text-sm flex justify-center items-center sp:h-4 sp:w-4 sp:-top-1 sp:-right-1.5"
          >
            {totalQuantity}
          </div>
        )}
      </div>
      {isDropdownOpen && (
        <div tabIndex={0} className="dropdown-content bg-base-100 rounded-box rounded-md z-[1] w-96 py-2 px-4 shadow sp:w-80">
          <p className="font-light text-sm text-center py-2">{`You have ${carts.length ?? "none"} shipping cart${carts.length > 1 ? "s" : ""}`}</p>
          <div className="border-y">
            {carts.map((cart) => {
              const cartTotalQuantity = cart.lines.reduce((lineTotal, line) => lineTotal + line.quantity, 0);
              return (
                <div
                  key={cart.hsCartId}
                  className="py-3 px-1 border-b last:border-none"
                >
                  <div className="flex items-center py-1">
                    <BuildingStorefrontIcon width="20" height="20" />
                    <p className="ml-1">{cart.shop.name}</p>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-sm">{`${cartTotalQuantity} bottles`}</div>
                    <div>{`$${cart.cost.totalAmount.amount}`}</div>
                  </div>
                  <ul className="flex items-center flex-wrap gap-8 py-3">
                    {cart.lines.map((line) => {
                      const merchandise = line.merchandise;
                      return (
                        <li
                          key={line.id}
                          className="relative"
                        >
                          <Image
                            src={merchandise.image.url || "/images/no-image-sake.png"}
                            width={16}
                            height={50}
                            alt={merchandise.title}
                            title={merchandise.title}
                            className="w-auto min-h-[50px] h-[50px]"
                            unoptimized
                          />
                          <div className="absolute -top-1 -right-4 h-5 w-5 bg-white rounded-full text-xs flex justify-center items-center shadow">
                            {line.quantity}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="flex justify-between items-center my-2">
                    <Link
                      href={`/shops/${cart.shop.id}`}
                      title={`${cart.shop.name}'s sakes`}
                    >
                      <div className="sp:hidden">
                        <Button inverse>Shop more</Button>
                      </div>
                      <div className="hidden sp:block">
                        <Button inverse small className="text-sm">Shop more</Button>
                      </div>
                    </Link>
                    <Link
                      href={`/carts/${cart.hsCartId}`}
                      title={`${cart.shop.name}'s cart`}
                    >
                      <div className="sp:hidden">
                        <Button>Go to cart</Button>
                      </div>
                      <div className="hidden sp:block">
                        <Button small className="text-sm">Go to cart</Button>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <div className="text-center pt-4 pb-2">
              <Link
                href={"/carts"}
                title="Show all carts"
                className="text-secondary font-light"
              >
                Show all carts
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
