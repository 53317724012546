"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { sendPageView } from "@/utils/analytics";

// ページ遷移を監視して、GA4にページビューイベントを送る
export default function GaPageViewListener() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!pathname) return;

    const url = searchParams?.toString()
      ? `${pathname}?${searchParams.toString()}`
      : pathname;

    sendPageView(url);
  }, [pathname, searchParams]);

  return null;
}
