import { Link } from '@/navigation';
import { getTranslations } from 'next-intl/server';

export default async function Footer() {
  const t = await getTranslations('Footer');

  return (
    <footer className="flex justify-center mt-28 sp:mt-20">
      <div className="w-full max-w-[1080px] border-t border-gray-300 py-12 sp:py-8">
        <div className="flex justify-between mb-14 sp:justify-center sp:mb-8">
          <div>
            {/* SNSアイコン等 */}
          </div>
          <nav className="">
            <ul className="flex justify-center items-center gap-8 sp:gap-4">
              <li className="text-gray-400 sp:text-sm">
                <a href="https://hellosake.notion.site/terms-of-use" target="_blank" title={t('termsOfUse')} rel="nofollow">{t('termsOfUse')}</a>
              </li>
              <li className="text-gray-400 sp:text-sm">
                <a href="https://hellosake.notion.site/Privacy-Policy-15ff580e91c98047a881e90acc96c5a8" target="_blank" title={t('privacyPolicy')} rel="nofollow">{t('privacyPolicy')}</a>
              </li>
              <li className="text-gray-400 sp:text-sm">
                <a href="https://hellosake.notion.site/terms-of-sales" target="_blank" title={t('termsOfSale')} rel="nofollow">{t('termsOfSale')}</a>
              </li>
              <li className="text-gray-400 sp:text-sm">
                <Link href="/contact" title={t('contact')}>{t('contact')}</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="flex justify-center">
          <p className="text-sm text-gray-400 sp:text-xs">© HelloSake 2024</p>
        </div>
      </div>
    </footer>
  );
}
