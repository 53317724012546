import useSWR from "swr";
import { CartShopifyProductRes } from "@/types/shopify";

const fetcher = (url: string): Promise<CartShopifyProductRes> =>
  fetch(url)
    .then((res) => res.json())
    .then((res) => res.results);

export const useCarts = () => {
  const res = useSWR<CartShopifyProductRes>("/api/shopify/carts", fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  return res;
};
