'use client';

import React from "react";
import Image from "next/image";
import {
  SHIP_TO_COUNTRIES,
  STATES,
} from "@/utils/constants";
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { updateUser } from "@/services/user";
import {
  Bars3Icon,
} from "@heroicons/react/24/outline";

export default function HamburgerMenu() {
  const { data: currentUser, mutate } = useCurrentUser();

  const shipCountry = currentUser?.shipCountry;
  const shipState = currentUser?.shipState;

  const handleSelectCountry = async (value: keyof typeof SHIP_TO_COUNTRIES) => {
    await updateUser({ shipCountry: value });
    await mutate();
  };

  const handleSelectState = async (value: keyof typeof STATES) => {
    await updateUser({ shipState: value });
    await mutate();
  };

  return (
    <div className="drawer hidden sp:block">
      <input
        id="hamburger"
        type="checkbox"
        className="drawer-toggle"
      />
      <div className="drawer-content inline-flex">
        <label htmlFor="hamburger" className="drawer-button cursor-pointer">
          <Bars3Icon width="24" height="24" />
        </label>
      </div>
      <div className="drawer-side z-50">
        <label htmlFor="hamburger" aria-label="close sidebar" className="drawer-overlay"></label>
        <div className="menu bg-base-200 text-base-content min-h-full w-80 p-4">
          <div className="collapse collapse-arrow bg-base-200">
            <input type="checkbox" />
            <div className="collapse-title text-lg font-light">
              <span>Ship to</span>
              <span className="ml-2 text-neutral">{shipCountry ? SHIP_TO_COUNTRIES[shipCountry].name : "(Click to select)"}</span>
            </div>
            <div className="collapse-content">
              {Object.keys(SHIP_TO_COUNTRIES).map((value) => {
                const data = SHIP_TO_COUNTRIES[value];
                return (
                  <div
                    key={value}
                    className="flex items-center text-sm font-extralight p-2 rounded-lg cursor-pointer group"
                    onClick={async () => {
                      await handleSelectCountry(value);
                    }}
                  >
                    {data.icon && (
                      <Image
                        src={data.icon}
                        width={20}
                        height={20}
                        alt={data.name}
                        title={data.name}
                        unoptimized
                        className="mr-2"
                      />
                    )}
                    <p className={`${shipCountry === value ? "font-medium" : ""} group-hover:underline hover:text-secondary`}>{data.name}</p>
                    {data.comingSoon && <p className="text-xs text-neutral grow text-right">Coming soon</p>}
                  </div>
                )
              })}
            </div>
          </div>
          {shipCountry === "US" && (
            <div className="collapse collapse-arrow bg-base-200">
              <input type="checkbox" />
              <div className="collapse-title text-lg font-light">
                <span>State</span>
                <span className="ml-2 text-neutral">{shipState ? STATES[shipState] : "(Click to select)"}</span>
              </div>
              <div className="collapse-content">
                {Object.keys(STATES).map((value) => {
                  return (
                    <div
                      key={value}
                      className="flex items-center text-sm font-extralight p-2 rounded-lg cursor-pointer group"
                      onClick={async () => {
                        await handleSelectState(value);
                      }}
                    >
                      <p className={`${shipState === value ? "font-medium" : ""} group-hover:underline hover:text-secondary`}>{STATES[value]}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
