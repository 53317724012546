import { handleFailed, handleSucceed, path } from "../";
import {
  SHIP_TO_COUNTRIES,
} from "@/utils/constants";

interface AddToCartParams {
  variantId: string;
  quantity: number;
  shopId: string;
}
export async function addToCart(params: AddToCartParams) {
  return fetch(path("/api/shopify/carts/add"), {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params),
  })
    .then(handleSucceed)
    .catch(handleFailed);
}

interface DeleteFromCartParams {
  shopId: string;
  lineId: string;
}
export async function deleteFromCart(params: DeleteFromCartParams) {
  return fetch(path("/api/shopify/carts/variants/delete"), {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params),
  })
    .then(handleSucceed)
    .catch(handleFailed);
}

interface UpdateQuantityFromCartParams {
  shopId: string;
  lineId: string;
  merchandiseId: string;
  quantity: number;
}
export async function updateQuantityFromCart(params: UpdateQuantityFromCartParams) {
  return fetch(path("/api/shopify/carts/variants/update"), {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params),
  })
    .then(handleSucceed)
    .catch(handleFailed);
}

interface UpdateBuyerIdentityParams {
  buyerIdentity: {
    countryCode: keyof typeof SHIP_TO_COUNTRIES;
    email: string;
    phone?: string | null;
    deliveryAddressPreferences: {
      deliveryAddress: {
        address1: string;
        address2: string | undefined;
        city: string;
        country: string;
        firstName: string;
        lastName: string;
        phone?: string;
        province: string;
        zip: string;
      };
    }[];
  };
}
export async function updateBuyerIdentity(cartId: string, params: UpdateBuyerIdentityParams) {
  return fetch(path(`/api/shopify/carts/${cartId}/buyer_identities`), {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params),
  })
    .then(handleSucceed)
    .catch(handleFailed);
}
