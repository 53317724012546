"use client";

import { useState } from "react";
import { useRouter } from "next/navigation";
import { Sake } from "@/types";
import { Button } from "@/components";
import { addToCart } from "@/services/shopify";
import { useCarts } from "@/hooks/shopify/useCarts";
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { CURRENCIES } from "@/utils/constants";
import { sendEvent } from "@/utils/analytics";

type Props = {
  sake: Sake;
};

export default function AddToCartButtonSection({
  sake,
}: Props) {
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: currentUser, isLoading } = useCurrentUser();
  const { mutate } = useCarts();

  if (isLoading) return null;

  const shipCountry = currentUser?.shipCountry;
  const currencyObj = CURRENCIES[shipCountry as string];
  let price;
  let mark;
  let isPositionPrev;
  if (currencyObj) {
    const currency = currencyObj.currency;
    price = sake.prices ? sake.prices[currency].price : null;
    mark = currencyObj.mark;
    isPositionPrev = currencyObj.isPositionPrev;
  } else {
    // shipCountryが指定されていない、または見つからない場合は、usdをデフォルトとする
    price = sake.prices ? sake.prices["usd"]?.price : null;
    mark = "$";
    isPositionPrev = true;
  }

  const { defaultVariantId, defaultShopId } = sake;

  if (!price || !defaultVariantId || !defaultShopId) return null;

  const handleAdd = async () => {
    if (!currentUser) {
      const dialog = document.querySelector("#signin-modal") as HTMLDialogElement;
      dialog.showModal();
      return;
    }
    try {
      setIsSubmitting(true);
      const res = await addToCart({
        variantId: defaultVariantId,
        shopId: defaultShopId,
        quantity: 1,
      });
      if (res.status === 200) {
        sendEvent("add_to_cart", {
          currency: currencyObj.currency.toUpperCase(),
          value: price,
          items: [
            {
              item_id: sake.id,
              item_name: sake.name,
              price: price,
              quantity: 1,
            },
          ],
        });
        router.push("/carts");
      }
    } finally {
      mutate();
      setIsSubmitting(false);
    }
  };

  const isDisable = !sake.availableRegions || !currentUser || !sake.availableRegions.includes(`${currentUser.shipCountry}-${currentUser.shipState}`);

  return (
    <div
      className={`mt-4 w-full px-1 ${isDisable ? "tooltip" : ""}`}
      data-tip={isDisable ? "Not available in your area." : undefined}
    >
      <Button
        small
        onClick={handleAdd}
        className={`w-full !px-2 font-medium ${isSubmitting || isDisable ? "btn-disabled" : ""}`}
      >
        {isSubmitting ? (
          <span className="loading loading-spinner loading-sm text-secondary"></span>
        ) : isPositionPrev ? `${mark}${price}` : `${price}${mark}`}
      </Button>
    </div>
  );
}
