"use client";

import { useEffect } from 'react';
import { useSession } from "next-auth/react";
import { useCurrentUser } from '@/hooks/useCurrentUser';
import CurrentUser from "./CurrentUser";
import {
  UserCircleIcon,
} from "@heroicons/react/24/outline";

export default function SignInButton() {
  const { data: session } = useSession();
  const { data: currentUser, mutate } = useCurrentUser();

  useEffect(() => {
    if (session) {
      mutate();
    }
  }, [session, mutate]);

  if (session && currentUser) return <CurrentUser />;

  const handleClick = () => {
    const dialog = document.querySelector("#signin-modal") as HTMLDialogElement;
    dialog.showModal();
  };

  return (
    <div
      className="flex flex-col items-center text-neutral cursor-pointer hover:text-black"
      onClick={handleClick}
    >
      <UserCircleIcon width="30" height="30" />
      <span className="text-sm font-medium leading-none -mt-1">Login</span>
    </div>
  );
}
