import React from "react";

type Props = {
  children: React.ReactNode;
  inverse?: boolean;
  onClick?: () => void;
  wide?: boolean;
  block?: boolean;
  small?: boolean;
  className?: string;
};

export default function Button({
  children,
  inverse,
  onClick,
  wide,
  block,
  small,
  className = "",
}: Props) {
  return (
    <button
      className={`btn bg-black border-black text-base font-bold px-8 hover:bg-black hover:border-black hover:opacity-70 ${inverse ? "bg-white text-black hover:bg-gray-200 hover:text-black btn-outline" : "text-white"} ${wide ? "btn-wide" : ""} ${block ? "btn-block" : ""} ${small ? "btn-sm" : ""} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
