"use client";

export const sendEvent = async (
  eventName: string,
  eventParams?: Record<string, any>
) => {
  if (typeof window === "undefined") return;

  if (window.gtag) {
    // https://developers.google.com/analytics/devguides/collection/ga4/events?hl=ja
    window.gtag("event", eventName, eventParams);
  }
};

export const sendPageView = (url: string) => {
  if (typeof window === "undefined") return;

  if (window.gtag) {
    window.gtag("config", "G-6PKYPR869L", {
      page_path: url,
    });
  }
};
