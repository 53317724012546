export const COUNT_PER_PAGE = 20;
export const PREFECTURES = [
  "Hokkaido",
  "Aomori",
  "Iwate",
  "Miyagi",
  "Akita",
  "Yamagata",
  "Fukushima",
  "Ibaraki",
  "Tochigi",
  "Gunma",
  "Saitama",
  "Chiba",
  "Tokyo",
  "Kanagawa",
  "Niigata",
  "Toyama",
  "Ishikawa",
  "Fukui",
  "Yamanashi",
  "Nagano",
  "Gifu",
  "Shizuoka",
  "Aichi",
  "Mie",
  "Shiga",
  "Kyoto",
  "Osaka",
  "Hyogo",
  "Nara",
  "Wakayama",
  "Tottori",
  "Shimane",
  "Okayama",
  "Hiroshima",
  "Yamaguchi",
  "Tokushima",
  "Kagawa",
  "Ehime",
  "Kochi",
  "Fukuoka",
  "Saga",
  "Nagasaki",
  "Kumamoto",
  "Oita",
  "Miyazaki",
  "Kagoshima",
  "Okinawa",
];
export const TAG_COLORS: {
  [key: string]: string;
} = {
  "Sweet": "#f8bbd0",
  "Acid": "#f0f4c3",
  "Umami": "#d7ccc8",
  "Bitter": "#c8e6c9",
  "Astringent": "#d1c4e9",
  "Gamey": "#ffcdd2",
  "Mellow": "#fff9c4",
  "Mild": "#b3e5fc",
  "Complex": "#e1bee7",
  "Creamy": "#ffecb3",
  "Crisp": "#dcedc8",
  "Dry": "#e0e0e0",
  "Full-bodied": "#bcaaa4",
  "Smooth": "#bbdefb",
  "Caramel": "#d7ccc8",
  "Unripe/Green Banana": "#fff9c4",
  "Chestnut": "#bcaaa4",
  "White Pepper": "#eeeeee",
  "Mineral": "#cfd8dc",
  "Butter": "#fff59d",
  "Cream Cheese": "#ffecb3",
  "Rice Flour": "#fafafa",
  "Cooked Rice": "#efebe9",
  "Floral": "#f8bbd0",
  "Blossoms": "#fce4ec",
  "Rose": "#ffebee",
  "Jasmine": "#fffde7",
  "Fruity": "#c8e6c9",
  "Melon": "#e8f5e9",
  "Lychee": "#f3e5f5",
  "Pear": "#e8f5e9",
  "Green Apple": "#c8e6c9",
  "Banana": "#fffde7",
  "Berry": "#ffcdd2",
  "Pineapple": "#fff9c4",
  "Citrus": "#fff9c4",
  "Herbaceous": "#c8e6c9",
  "Grass": "#a5d6a7",
  "Earthy": "#d7ccc8",
  "Mushroom": "#d7ccc8",
  "Oak": "#d7ccc8",
  "Vanilla": "#ffecb3",
  "Spicy": "#ffccbc",
  "Anise": "#ffab91",
  "Pepper": "#ffab91",
  "Cinnamon": "#ffab91",
  "Almond": "#ffe082",
  "Honey": "#ffecb3",
  "Chocolate": "#bcaaa4",
  "Red fruit": "#ffcdd2",
  "Black fruit": "#b0bec5",
  "Tree fruit": "#d7ccc8",
  "Citrus Fruit": "#fff9c4",
  "Tropical fruit": "#c8e6c9",
  "Vegetal": "#dcedc8",
  "Oaky": "#ffecb3",
  "Spices": "#ffccbc",
  "Ageing": "#cfd8dc",
  "Yeasty": "#d7ccc8",
  "Dried fruit": "#ffe0b2",
}
export const PARING_IMAGES: {
  [key: string]: string;
} = {
  "Sashimi": "/images/paring/sashimi.png",
  "Grilled Fish": "/images/paring/grilled-fish.png",
  "Tempura": "/images/paring/tempura.png",
  "Meat": "/images/paring/meat.png",
  "Cheese": "/images/paring/cheese.png",
  "Pickled Vegetables": "/images/paring/pickled-vegetables.png",
  "Fried Foods": "/images/paring/fried-foods.png",
  "Sushi": "/images/paring/sushi.png",
  "Tofu Dishes": "/images/paring/tofu-dishes.png",
  "Seafood": "/images/paring/seafood.png",
  "Vegetable Stir Fry": "/images/paring/vegetable-stir-fry.png",
  "Ramen": "/images/paring/ramen.png",
  "Barbecue": "/images/paring/barbecue.png",
  "Pasta": "/images/paring/pasta.png",
  "Pizza": "/images/paring/pizza.png",
  "Salads": "/images/paring/salads.png",
  "Soup": "/images/paring/soup.png",
  "Burgers": "/images/paring/burgers.png",
  "Sandwiches": "/images/paring/sandwiches.png",
  "Desserts": "/images/paring/desserts.png",
  "Chocolate Desserts": "/images/paring/desserts.png",
}
export const BusinessCard: {
  [key: string]: {
    name: string;
    sub: string;
    description: string;
    image: string;
    email?: string;
    twitter?: string;
    linkedin?: string;
    instagram?: string;
    newsletter?: string;
    facebook?: string;
  };
} = {
  "kenta": {
    "name": "Kenta Hara",
    "sub": "Co-Founder & CEO",
    "description": "I'm a software engineer with 6 years of solid experience. Nowadays, my primary focus has been building a high-performance web application using React. However, I also have experience in backend development and managing team. I'm an outstanding individual contributor and passionate about building a revolutionary product that improves the quality of life through teamwork.",
    "image": "/images/business-card/kenta.avif",
    "email": "kenta@hellosake.com",
    "twitter": "https://twitter.com/hellokenta_ja",
    "linkedin": "https://www.linkedin.com/in/kenta-hara-18003797/",
    "instagram": "https://www.instagram.com/kenta_loves_sake/",
    "newsletter": "https://hellosake.beehiiv.com/",
    "facebook": "https://www.facebook.com/kenta.hara.142",
  },
  "ryosuke": {
    "name": "Ryosuke Kuga",
    "sub": "Co-Founder & CSO",
    "description": "A connoisseur with three refrigerators at home, exclusively for storing his collection of 160 different sakes, he has cultivated extensive relationships with breweries and liquor stores throughout Japan. At HelloSake, he leverages these relationships along with his deep knowledge of sake to lead strategies and business development, aiming to enhance the appreciation and accessibility of this traditional Japanese beverage worldwide.",
    "image": "/images/business-card/ryosuke.avif",
    "email": "ryosuke@hellosake.com",
    "facebook": "https://www.facebook.com/ryosuke.kuga.9",
  },
  "daiaoki": {
    "name": "Dai Aoki",
    "sub": "Co-Founder & CTO",
    "description": "I'm a software engineer. I love sake, my dogs, and my wife. Bringing the goodness of Japan to the world through technology and passion.",
    "image": "/images/business-card/daiaoki.jpg",
    "email": "daiaoki@hellosake.com",
    "twitter": "https://twitter.com/DaiAoki30",
    "facebook": "https://www.facebook.com/dai.aoki.94",
  },
}

export const PUBLIC_WRITERS = [
  {
    id: "1",
    name: "Dai Aoki",
    image: "/images/business-card/daiaoki.jpg",
  },
  {
    id: "2",
    name: "Ryosuke Kuga",
    image: "/images/business-card/ryosuke.avif",
  },
  {
    id: "3",
    name: "Kenta Hara",
    image: "/images/business-card/kenta.avif",
  },
  {
    id: "4",
    name: "Chizuru Fukui",
    image: "/images/business-card/chizuru.jpeg",
  },
  {
    id: "9999",
    name: "Guest",
    image: "/images/no-image-user.png",
  },
];

export const LICENSES = [
  "SSA (Certified)",
  "SSA (Advanced)",
  "SSA (Master)",
  "WSET 1",
  "WSET 3",
  "Sake Diploma",
  "SSI (Kikisake-shi)",
  "SSI (Navigator)",
  "SSI (Master)"
];

export const SHIP_TO_COUNTRIES: {
  [key: string]: {
    name: string;
    icon?: string;
    comingSoon?: boolean;
  };
} = {
  "US": {
    "name": "United States",
    "icon": "/images/flag/us.svg",
  },
  "JP": {
    "name": "Japan",
    "icon": "/images/flag/jp.svg",
    "comingSoon": true,
  },
  "OTHERS": {
    "name": "Others",
  },
};

export const STATES: {
  [key: string]: string;
} = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American Samoa",
  "AX": "Arizona",
  "AR": "Arkansas",
  "AA": "Armed Forces Americas",
  "AE": "Armed Forces Europe",
  "AP": "Armed Forces Pacific",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District Of Columbia",
  "FM": "Federated States of Micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall Islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming",
};

export const CURRENCIES: {
  [key: string]: {
    currency: string;
    mark: string;
    isPositionPrev: boolean;
  };
} = {
  "US": {
    "currency": "usd",
    "mark": "$",
    "isPositionPrev": true,
  },
};
