import { Link } from '@/navigation';
import Image from "next/image";
import { Sake } from "@/types";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { RatingStar } from "@/components";
import AddToCartButtonSection from "./AddToCartButtonSection";

type Props = {
  sake: Sake;
  rank?: number;
  className?: string;
};

export default function SakeCard({
  sake,
  rank,
  className,
}: Props) {
  let bgColorClass;
  if (rank === 1) {
    bgColorClass = "bg-primary";
  } else if (rank === 2) {
    bgColorClass = "bg-custom-gray";
  } else if (rank === 3) {
    bgColorClass = "bg-custom-brown";
  }
  return (
    <li className={`w-44 sp:w-48 ${className || ""}`}>
      <Link
        href={`/products/${sake.id}`}
        title={sake.name}
        className="pt-8 w-full sp:pt-4"
      >
        <div className="card h-full custom-shadow relative p-4">
          {rank && rank < 4 && <span className={`${bgColorClass ? bgColorClass : ""} absolute -top-2 -left-2 w-6 h-6 rounded-full text-white text-center`}>{rank}</span>}
          <div className="flex items-end">
            <div className="flex justify-center items-end basis-2/5 h-28">
              <Image
                src={sake.labelImageUrl ? `${process.env.NEXT_PUBLIC_SAKE_LABEL_IMAGE_URL_BASE}${sake.labelImageUrl}` : "/images/no-image-sake.png"}
                width={36}
                height={36}
                alt={sake.name}
                title={sake.name}
                className="-mt-10 min-w-[36px] custom-drop-shadow max-h-40 object-contain sp:max-h-36"
              />
            </div>
            <div className="basis-3/5 place-self-center">
              {sake.averageScore ? (
                <div className="flex justify-center mb-2 tooltip" data-tip="Score from Experts">
                  <div className="flex items-center" >
                    <Image
                      src={"/images/verified.svg"}
                      width={30}
                      height={30}
                      alt="verified"
                      title="verified"
                      unoptimized
                      className="sp:w-[26px]"
                    />
                    <p className="text-3xl ml-1 sp:text-2xl">{sake.averageScore}</p>
                  </div>
                </div>
              ) : null}
              <div className="flex flex-col items-center">
                <div
                  className={sake.averageScore ? "flex items-center tooltip" : "flex flex-col items-center tooltip"}
                  data-tip="Rating from Customers"
                >
                  {!sake.averageScore ? <p className="text-3xl mb-0.5">{sake.averageRating || "-"}</p> : null}
                  <RatingStar rating={sake.averageRating} />
                  {sake.averageScore ? <p className="text-primary ml-1">{sake.averageRating || "-"}</p> : null}
                </div>
                {sake.reviewCount > 0 && <p className="text-xs">{`${sake.reviewCount} reviews`}</p>}
                <AddToCartButtonSection sake={sake} />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <h3 className="text-base font-medium leading-tight line-clamp-2 sp:text-sm">{sake.name}</h3>
            <div className="flex items-center mt-1">
              <MapPinIcon className="h-6 w-6 sp:h-5 sp:w-5" />
              <p className="text-xs truncate">{sake.brewery?.name || "-"}</p>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
}
