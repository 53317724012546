import Image from "next/image";
import { PARING_IMAGES } from "@/utils/constants";

type Props = {
  bestFoodPairings?: string[];
};

export default function Pairing({
  bestFoodPairings,
}: Props) {
  return (
    <div className="py-4">
      {!bestFoodPairings || bestFoodPairings.length === 0 ? (
        <div className="text-lg text-custom-gray sp:text-base">no data</div>
      ) : (
        <ul className="flex flex-wrap gap-4 sp:gap-2">
          {[...(bestFoodPairings || [])].map((paring) => (
            <li
              key={paring}
              className="flex flex-col items-center w-24 sp:w-20"
            >
              <Image
                src={PARING_IMAGES[paring] || "/images/no-image.png"}
                width={96}
                height={60}
                alt={paring}
                title={paring}
                unoptimized
              />
              <p className="text-center font-medium leading-tight mt-1 sp:text-sm sp:font-normal">{paring}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
